.main {
    background-color: white;
    margin: 10px auto;
    width: 90%;
    padding: 20px;
    text-align: center;
}

.welcome {
    display: block;
    font-size: 14px;
    margin-top: 20px;
}

.card-icon {
    font-size: 40px;
}

.home-row {
    padding-top: 10px;
}