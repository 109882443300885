
.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-intro {
    font-size: large;
}

.wizard-header-logo {
  height: 65px;
  padding-top: 0px;
}

.wizard-header {
  background-color: white!important;
  padding-left: 20px;
  height: 70px;
}
.wizard-content {
  background-color:white;
}

.wizard-footer {
  height: 30px;
  padding-top: 10px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
