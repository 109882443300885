.header-left {
    float: left;
    padding-left: 20px;
    text-align: left;
}

.header-right {
    float: right;
    padding-right: 20px;
    text-align: right;
}

.domain {
    font-size: 19px;
    font-weight: bold;
    display: block;
}

.type {
    font-size: 16px;
    /*font-weight: bold;*/
    display: block;
}

.date {
    color: #BFBFC3;
    font-size: 12px;
    /*font-weight: bold;*/
    display: block;
}

.status {
    margin-top: 5px;
    color: #BFBFC3;
    font-size: 11px;
    display: block;

}

.eppMessage-detail-header {
    padding: 20px;
    background-color: #FAFAFA;
    border-top: 1px solid #E8E8E8;
}

.eppMessage-detail-body {
    /*background-color: red;*/
    padding: 30px;
    /*display: flex;*/
    /*align-content: flex-start;*/
    /*justify-content: flex-start;*/
}

.message-detail-icon {
    padding-top: 2px;
    padding-bottom: 3px;
    /*margin-left: 10px;*/
    background-color: #CCCCCC;
    /*border: 2px solid ;*/
    border-radius: 50px 20px;
    font-weight: bold;
    color: white;
}
