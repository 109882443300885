.eppChecked-icon {
    margin-left: 15px;
    font-size: 40pt;
    font-weight: 700;
    line-height: 1.5;
}

.eppChecked-icon-success {
    color: #18A658
}

.eppChecked-icon-fail {
    color: #ED433C
}

.eppChecked-box-message-success {
    background-color: #EBF8F2;
}

.eppChecked-box-message-fail {
    background-color: #fff0ef;
}

.eppChecked-box-success {
    border: 1px solid #D0EFDF;
}

.eppChecked-box-fail {
    border: 1px solid #FCDBD9;
}

.eppChecked-title {
    margin-left: 10px;
    /*font-size: 20px;*/
    padding-bottom: 20px;
}

.eppChecked-box {
    border-radius: 5px;
    padding-bottom: 20px;
}

.eppChecked-box-message {
    padding: 10px;
    margin-bottom: 30px;
    color: black;
}

.eppChecked-box-extra {
    margin: 30px 80px;
    padding: 20px;
    background-color: #FAFAFA;
    font-size: 10pt;
}

.eppChecked-box-body {
    margin-bottom: 20px;
    font-size: 14pt;
    font-weight: 700;
}

/*.eppChecked-card {*/
    /*padding-left: 35%;*/
    /*padding-right: 35%;*/
    /*font-size: 12pt;*/
/*}*/