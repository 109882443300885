.itlogo-footer {
    height: 20px;
    width: auto;
    position: relative;
    top: 7px;
    left: -5px;
}

a.footer-link {
    color: white;
    font-weight: 700;
    font-size: 11pt;
}

.epic-footer {
    text-align: center;
    padding-top: 5px;
    padding-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.65) !important;
    color: white;
}
