
.ant-modal-footer {
  border-top:none;
}

.ant-modal-header {
  border-bottom: none;
}

.ant-modal-title {
  color: black;
}

/* use css to set position of modal */
.vertical-center-modal {
  /*white-space: nowrap;*/
  display: grid;
}

.vertical-center-modal:before {
  content: '';

  height: 100%;
  vertical-align: middle;
  width: 0;
}

.reset-icon {
  left:94% !important;
  font-size:10pt !important;
  top:-47px !important;
}

.alert-message {
  margin-bottom: 20px;
}
