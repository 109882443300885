.filter-div {
    padding-top: 15px;
    padding-bottom: 10px;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    z-index: 2;
    background-color: white;
    align-items: center;
}

.filter-div-options {
    padding-top: 0px !important;
}

.filter-menu {
    /*border-bottom: 1px solid #e5e5e5;*/
}

.extra {
    width: 250px;
}

.filter-menu-input-search {
    padding: 10px;
}

.filter-menu-item {
    height: 30px !important;
    padding-left: 10px !important;
}

.filter-menu-row {
}

.filter-menu-icon {
    color: black;
    vertical-align: 0 !important;
    /*margin-right: 5px;*/
    width: 25px !important;
}

.filter-menu-wrapped {
    margin-left: 20px;
}

.filter-menu-text {
    vertical-align: 1px !important;
}

.operation {
    display: block;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
}

/* mouse over link */
.operation:hover {
    color: #1890ff;
    cursor: pointer;
}

.filter-bar-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #e5e5e5;
    padding: 5px;
    border-radius: 5px;
}

.filter-bar-left {
    display: flex;
    align-items: center;
    width: 100%;
    /*height: auto;*/
    /*border: 1px solid;*/
}

.filter-bar-right {
    display: flex;
    align-items: center;
    width: 20px;
}

/* mouse over link */
.filter-bar-right:hover {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #1890ff;
    font-weight: bold;
}

.filter-options-exit {
    padding: 1px;
    margin: 2px;
    border-radius: 3px;
    border: 1px solid #e5e5e5;
}

.filter-options-exit:hover {
    cursor: pointer;
    color: #1890ff;
}

.div-exit {
    /*border-bottom: 1px solid #e5e5e5;*/
    display: flex;
    justify-content: flex-end;
}