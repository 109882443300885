.align-left {
  text-align: left;
}
.epic-footer {
  text-align: center;
}




.epic-footer > a {
  font-weight: 700;
  color: white!important;
}

.ant-layout-footer {
  background-color: white!important;
  padding-top: 30px;
}
.itlogo-footer {
  width: 2%;
  height: auto;
}
