.domain-name {

}

.icon-domain-signed {
    margin-left: 6px;
    font-size: 11pt;
    font-weight: 800;
    color: #00a854;
}
