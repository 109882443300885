.riquadro {
  flex: 1 1 auto;
  /*display: flex;*/
  /*border: 1px dashed rgb(239, 239, 239);*/
  padding: 20px;
  margin-bottom: 10px;
  align-items: center;
}

.bordo {
  border: 1px dashed rgb(239, 239, 239);
}

.riquadro.bordo {
  margin-bottom: 30px;
}

.with-title {
  position: relative;
}

.title {
  position: absolute;
    top: -10px;
    background: white;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 800;
    font-size: 10pt;
    color: #3d8ee9;
}

.readonly {
    color: #b9b9b9 !important;
}

th.table-header {
  font-weight: 800!important;
}

.close-icon {
  position:relative;
  float:right;
}

.close-button {
  position: absolute;
  top: -30px;
  left: 5px;
  background: white;
  border-radius: 1px;
  padding-left: 5px;
  padding-bottom: 5px;
  font-weight: 800;
  font-size: 13pt;
  color: #3d8ee9;
  cursor: pointer;
}
.close-button[disabled] {
  cursor: default;
  color:#f0f0f0;
}
.ant-collapse-header {
  text-align: left;
    font-weight: 800;
    background-color: #f7f7f7;
}

/*
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  border-top: 1px solid #f7f7f7;
}
*/
.contact-id {
  border:1px solid #3d8ee9;
  border-radius: 5px;
  /*background-color:#3d8ee9;*/
  /*color:white;*/
  padding: 5px;

}

.wizard-component-header {
    /*margin-top: -70px;*/
}
.wizard-component-header-data {
    margin-top: -70px;
  margin-bottom: 50px;
  font-size: 12pt;

  border-bottom: 1px solid #108ee9;
  /*background-color: #74bcd6;*/
/*
font-weight: 700;
  color: #74bcd6;
  border-radius: 10px;
  */
  margin-left: 10%;
  margin-right: 10%;

}

.wizard-component-header-underline {
/*  border-bottom: 4px solid #108ee9;
  padding-bottom: 4px;*/
  /*border-radius: 2px;*/
}


.wizard-component-header-icon {
  padding-right: 5px;
  color: #108ee9;
}
