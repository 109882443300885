.eppMessage-detail-body {
    /*background-color: magenta;*/
}

.body-container {
    /*background-color: green;*/
    /*border: 1px solid #e5e5e5;*/
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap
}

.base-message {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: left;
    /*background-color: blue;*/
    width: 100%;
}

.typedLayout-container {
    /*background-color: yellow;*/
    /*text-align: left;*/
    /*display: flex;*/
}

.typedLayout-data {
    border: 1px solid #e5e5e5;
    padding: 20px 100px 20px 0px;
}

.typedLayout-header {
    font-weight: bold;
    text-align: left;
    padding-bottom: 5px;
    width: 100%;
}

.typedLayout-row {
    text-align: left;
    width: 100%;
    padding-top: 10px;
    padding-left: 10px;
}

.label {
    display: inline-block;
    width: 150px;
    text-align: right;
    font-weight: bold;
    font-size: 10pt;
    line-height: 2em;
    vertical-align: top;
}

.label-mini {
    width: 100px;
}

.content {
    position: relative;
    display: inline-block;
    text-align: left;
    line-height: 2em;
    font-size: 10pt;
    margin-left: 5px;
    /*margin-right: 180px;*/
    max-width: 800px;

}

.unstyled-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

pre.body {
    width: 100%;
    display: block;
    max-height: 500px;
    overflow: auto;
    margin: 0;
    padding: 0 5px;
    background: #F3F2F2;
    background-image: -webkit-linear-gradient(#F3F2F2 50%, #EBEAEA 50%);
    background-image: -moz-linear-gradient(#F3F2F2 50%, #EBEAEA 50%);
    background-image: -ms-linear-gradient(#F3F2F2 50%, #EBEAEA 50%);
    background-image: -o-linear-gradient(#F3F2F2 50%, #EBEAEA 50%);
    background-image: linear-gradient(#F3F2F2 50%, #EBEAEA 50%);
    background-position: 0 0;
    background-repeat: repeat;
    background-attachment: local;
    background-size: 4em 4em;
    line-height: 2em;
    display: none;
}

.dns-error-test {
    border: 1px solid #e5e5e5;
    padding: 10px;
    margin-top: 5px;
}

.dns-error-row {
    text-align: left;
    width: 100%;
    padding-top: 3px;
    /*padding-left: 10px ;*/
}

.dns-error-header {
    padding-top: 20px;
    font-weight: bold;
    font-style: oblique;
}

.dns-error-label {
    font-weight: bold;
    text-align: left;
}

.dns-error-nameserver {
    padding: 10px;
    border: 1px solid #e5e5e5;
}

.pop-over {
    max-width: 800px;
}