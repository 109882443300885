.centered {
  position: fixed; /* or absolute */
  top: 50%;
  left: 50%;
 }
.spinner {
  position: fixed;
  top:50%;
  left: 50%;
  width: 5%;
  height: auto;
}
.embedded {
  width: 5%;
  height: auto;
}
