.card-error > .ant-card-body {
    /*padding: 10px */
    /*padding-left: 30%;*/
    /*padding-right: 30%;*/

}

.card-error {
    /*text-align: match-parent;*/
}

.error-box {
    text-align: left;
}

.ant-alert-with-description .ant-alert-description .ant-alert-message {
    margin-left: 40px;
    text-align: left;
}

span.ant-alert-message {
    line-height: 1.5;
    font-size: 16px !important;
}

span.ant-alert-description {
    font-size: 14px !important;
}