.panel-titlebar {
    display: flex;
    /*padding: 1px;*/
    padding-left: 10px;
    padding-top: 3px;
    background-color: #e5e5e5;
    position: relative;
    text-align: left;
    border: 1px solid #ccc ;
}

.panel-title{
    color:  #108ee9;
    text-shadow: 0 1px 0 white;
    font-weight: bold;
    font-size: 9pt;
}

.panel-content {
    border: 0;
    background: none;
    padding: 0.5em 1em;
    /*overflow: hidden;*/
}

.panel-body {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    /*padding-left: 1em;*/
    overflow: hidden;
}

/*.panel-field .label {*/
/*display: block;*/
/*float: left;*/
/*line-height: 1.5em;*/
/*!*This needs to be modified to fit *!*/

/*vertical-align: bottom;*/
/*!*float: left;*!*/
/*!*text-align: left;*!*/
/*font-weight: bold;*/
/*font-size: 9pt;*/
/*!*!*width: 180px;*!*!*/
/*!*padding: 3px;*!*/
/*!*clear: left;*!*/
/*!*border: 1px solid;*!*/
/*!*overflow: hidden;*!*/

/*}*/
.panel-field {
    overflow: hidden;
}

/*.panel-field .content{*/
/*float: left;*/
/*text-align: left;*/
/*line-height: 1.5em;*/
/*margin-left:15px;*/
/*font-size: 10pt;*/
/*!*overflow: hidden;*!*/
/*}*/

.panel-field .row {
    float: left;
    position: relative;
    margin-left: 0;
    margin-right: 0;
    height: auto;
    zoom: 1;
    display: block;
    padding: 3px;
}

.panel-field .content {
    position: relative;
    display: inline-block;
    text-align: left;
    line-height: 2em;
    font-size: 10pt;
    margin-left: 5px;
}

.panel-field .label {
    display: inline-block;
    width: 180px;
    text-align: right;
    font-weight: bold;
    font-size: 10pt;
    line-height: 2em;
    vertical-align: top;
}

.red-row{
    background-color: #FBF1F2;
}

.green-row{
    background-color: #F6FEEF;
}