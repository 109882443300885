.contact-status-bar {
    font-weight: 600;
}

.contact-status-bar .ant-tag {
    line-height: 30px;
    height: 30px;
    font-size: 9pt;
    cursor: default;
    background-color: transparent;
}

.contact-status-bar-icon {
    font-size: 12pt;
    font-weight: 100;
    color: #b3b3b3;
    margin-right: 5px;
}

.break-line {
    border-left: 1px solid #b3b3b3;
    margin-right: 15px;
    margin-left: 15px;
}
