.logo {
    text-align: center;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    margin-bottom: 40px;
}

.logo img {
    width: 100px;
    /*margin-right: 8px;*/
}

.splash {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
.message {
  text-align: center;
  color: #b3b3b3;
  font-size: 11pt;
}
