
.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-intro {
    font-size: large;
}

.wizard-header-logo {
  height: 65px;
  padding-top: 0px;
}

.wizard-header {
  background-color: white!important;
  padding-left: 20px;
  height: 70px;
}
.wizard-content {
  background-color:white;
}

.wizard-footer {
  height: 30px;
  padding-top: 10px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.logo {
    text-align: center;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    margin-bottom: 40px;
}

.logo img {
    width: 100px;
    /*margin-right: 8px;*/
}

.splash {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
.message {
  text-align: center;
  color: #b3b3b3;
  font-size: 11pt;
}

.card-error > .ant-card-body {
    /*padding: 10px */
    /*padding-left: 30%;*/
    /*padding-right: 30%;*/

}

.card-error {
    /*text-align: match-parent;*/
}

.error-box {
    text-align: left;
}

.ant-alert-with-description .ant-alert-description .ant-alert-message {
    margin-left: 40px;
    text-align: left;
}

span.ant-alert-message {
    line-height: 1.5;
    font-size: 16px !important;
}

span.ant-alert-description {
    font-size: 14px !important;
}
/*@import '../../../../../../node_modules/antd/dist/antd.css';*/

.steps-content {
    margin-top: 16px;
    /*border: 1px dashed #e9e9e9;*/
    border-radius: 6px;
    /*background-color: #fafafa;*/
    min-height: 200px;
    text-align: center;
    padding-top: 80px;
    padding-bottom: 60px;
    border-bottom: 1px solid #e9e9e9;
}

.steps-action {
    margin-top: 24px;
    text-align: center;
    /*margin-bottom: 24px;*/
}

.steps-header {
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: white;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
    max-width: none;
}
.align-left {
  text-align: left;
}
.epic-footer {
  text-align: center;
}




.epic-footer > a {
  font-weight: 700;
  color: white!important;
}

.ant-layout-footer {
  background-color: white!important;
  padding-top: 30px;
}
.itlogo-footer {
  width: 2%;
  height: auto;
}

.panel-titlebar {
    display: flex;
    /*padding: 1px;*/
    padding-left: 10px;
    padding-top: 3px;
    background-color: #e5e5e5;
    position: relative;
    text-align: left;
    border: 1px solid #ccc ;
}

.panel-title{
    color:  #108ee9;
    text-shadow: 0 1px 0 white;
    font-weight: bold;
    font-size: 9pt;
}

.panel-content {
    border: 0;
    background: none;
    padding: 0.5em 1em;
    /*overflow: hidden;*/
}

.panel-body {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    /*padding-left: 1em;*/
    overflow: hidden;
}

/*.panel-field .label {*/
/*display: block;*/
/*float: left;*/
/*line-height: 1.5em;*/
/*!*This needs to be modified to fit *!*/

/*vertical-align: bottom;*/
/*!*float: left;*!*/
/*!*text-align: left;*!*/
/*font-weight: bold;*/
/*font-size: 9pt;*/
/*!*!*width: 180px;*!*!*/
/*!*padding: 3px;*!*/
/*!*clear: left;*!*/
/*!*border: 1px solid;*!*/
/*!*overflow: hidden;*!*/

/*}*/
.panel-field {
    overflow: hidden;
}

/*.panel-field .content{*/
/*float: left;*/
/*text-align: left;*/
/*line-height: 1.5em;*/
/*margin-left:15px;*/
/*font-size: 10pt;*/
/*!*overflow: hidden;*!*/
/*}*/

.panel-field .row {
    float: left;
    position: relative;
    margin-left: 0;
    margin-right: 0;
    height: auto;
    zoom: 1;
    display: block;
    padding: 3px;
}

.panel-field .content {
    position: relative;
    display: inline-block;
    text-align: left;
    line-height: 2em;
    font-size: 10pt;
    margin-left: 5px;
}

.panel-field .label {
    display: inline-block;
    width: 180px;
    text-align: right;
    font-weight: bold;
    font-size: 10pt;
    line-height: 2em;
    vertical-align: top;
}

.red-row{
    background-color: #FBF1F2;
}

.green-row{
    background-color: #F6FEEF;
}
.ant-col-sm-tech {
  width: 63.5%;
}

.ant-col-sm-contact {
  width: 50%;
}



.contact-info-alignment {
  float: left;
  margin-top: 5px;
  margin-bottom: 10px;
}

.contact-info-bar {
  color: #b3b3b3;
  margin-right: 5px;
}

.contactPopover {
  width:30%;
}

div.ant-col-sm-tech div.ant-form-item-control.has-feedback.has-success:after,
div.ant-col-sm-tech div.ant-form-item-control.has-feedback.has-warning.has-feedback:after,
div.ant-col-sm-tech div.ant-form-item-control.has-feedback.has-error.has-feedback:after,
div.ant-col-sm-tech div.ant-form-item-control.has-feedback.is-validating.has-feedback:after {
  position: absolute;
  padding-right: 57px;
  padding-top: 2px;
  top: 0;
  right: 0;
  visibility: visible;
  pointer-events: none;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  font-family: 'anticon';
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  z-index: 1;
}

div.ant-col-sm-tech div.ant-form-item-control.has-success.has-feedback :after,
div.ant-col-sm-tech div.ant-form-item-control.has-warning.has-feedback:after,
div.ant-col-sm-tech div.ant-form-item-control.has-error.has-feedback:after,
div.ant-col-sm-tech div.ant-form-item-control.is-validating.has-feedback:after {
  position: absolute;
  padding-right: 57px;
  padding-top: 2px;
  top: 0;
  right: 0;
  visibility: visible;
  pointer-events: none;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  font-family: 'anticon';
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  z-index: 1;
}


div.ant-col-sm-contact div.ant-form-item-control.has-feedback.has-success:after,
div.ant-col-sm-contact div.ant-form-item-control.has-feedback.has-warning.has-feedback:after,
div.ant-col-sm-contact div.ant-form-item-control.has-feedback.has-error.has-feedback:after,
div.ant-col-sm-contact div.ant-form-item-control.has-feedback.is-validating.has-feedback:after {
  position: absolute;
  padding-right: 57px;
  padding-top: 2px;
  top: 0;
  right: 0;
  visibility: visible;
  pointer-events: none;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  font-family: 'anticon';
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  z-index: 1;
}


div.ant-col-sm-contact div.ant-form-item-control.has-success.has-feedback :after,
div.ant-col-sm-contact div.ant-form-item-control.has-warning.has-feedback:after,
div.ant-col-sm-contact div.ant-form-item-control.has-error.has-feedback :after,
div.ant-col-sm-contact div.ant-form-item-control.is-validating.has-feedback:after {
  position: absolute;
  padding-right: 57px;
  padding-top: 2px;
  top: 0;
  right: 0;
  visibility: visible;
  pointer-events: none;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  font-family: 'anticon';
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  z-index: 1;
}


.ant-modal-footer {
  border-top:none;
}

.ant-modal-header {
  border-bottom: none;
}

.ant-modal-title {
  color: black;
}

/* use css to set position of modal */
.vertical-center-modal {
  /*white-space: nowrap;*/
  display: grid;
}

.vertical-center-modal:before {
  content: '';

  height: 100%;
  vertical-align: middle;
  width: 0;
}

.reset-icon {
  left:94% !important;
  font-size:10pt !important;
  top:-47px !important;
}

.alert-message {
  margin-bottom: 20px;
}

.riquadro {
  flex: 1 1 auto;
  /*display: flex;*/
  /*border: 1px dashed rgb(239, 239, 239);*/
  padding: 20px;
  margin-bottom: 10px;
  align-items: center;
}

.bordo {
  border: 1px dashed rgb(239, 239, 239);
}

.riquadro.bordo {
  margin-bottom: 30px;
}

.with-title {
  position: relative;
}

.title {
  position: absolute;
    top: -10px;
    background: white;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 800;
    font-size: 10pt;
    color: #3d8ee9;
}

.readonly {
    color: #b9b9b9 !important;
}

th.table-header {
  font-weight: 800!important;
}

.close-icon {
  position:relative;
  float:right;
}

.close-button {
  position: absolute;
  top: -30px;
  left: 5px;
  background: white;
  border-radius: 1px;
  padding-left: 5px;
  padding-bottom: 5px;
  font-weight: 800;
  font-size: 13pt;
  color: #3d8ee9;
  cursor: pointer;
}
.close-button[disabled] {
  cursor: default;
  color:#f0f0f0;
}
.ant-collapse-header {
  text-align: left;
    font-weight: 800;
    background-color: #f7f7f7;
}

/*
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  border-top: 1px solid #f7f7f7;
}
*/
.contact-id {
  border:1px solid #3d8ee9;
  border-radius: 5px;
  /*background-color:#3d8ee9;*/
  /*color:white;*/
  padding: 5px;

}

.wizard-component-header {
    /*margin-top: -70px;*/
}
.wizard-component-header-data {
    margin-top: -70px;
  margin-bottom: 50px;
  font-size: 12pt;

  border-bottom: 1px solid #108ee9;
  /*background-color: #74bcd6;*/
/*
font-weight: 700;
  color: #74bcd6;
  border-radius: 10px;
  */
  margin-left: 10%;
  margin-right: 10%;

}

.wizard-component-header-underline {
/*  border-bottom: 4px solid #108ee9;
  padding-bottom: 4px;*/
  /*border-radius: 2px;*/
}


.wizard-component-header-icon {
  padding-right: 5px;
  color: #108ee9;
}

.div-title {
    text-align: left;
    margin: 10px;
    margin-top: 20px;
}

.page-title {
    font-size: 20pt;
}
.domain-status-bar {
    font-weight: 600;
}

.domain-status-bar .ant-tag {
    line-height: 20px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
    height: 20px;
    font-size: 9pt;
    cursor: default;
    background-color: transparent;
}

.domain-status-bar-icon {
    font-size: 10pt;
    font-weight: 100;
    color: #b3b3b3;
    margin-right: 7px;
}

.break-line {
    border-left: 1px solid #b3b3b3;
    margin-right: 15px;
    margin-left: 15px;
}

.domain-name {

}

.icon-domain-signed {
    margin-left: 6px;
    font-size: 11pt;
    font-weight: 800;
    color: #00a854;
}


.dsRecordsDetail-table {
    margin-bottom: 20px;
}
div.ant-col-12 .ant-table-wrapper {
    width: 100% !important;
}

.hosts-table {
    margin-bottom: 20px;
}

/*tr:nth-child(even) {*/
/*background: #fcfcfc*/
/*}*/

/*tr:nth-child(odd) {*/
/*background: #FfFF*/
/*}*/


.centered {
  position: fixed; /* or absolute */
  top: 50%;
  left: 50%;
 }
.spinner {
  position: fixed;
  top:50%;
  left: 50%;
  width: 5%;
  height: auto;
}
.embedded {
  width: 5%;
  height: auto;
}

.domain-status-box {
    /*border: 1px solid #999999;*/
    width: 90%;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 60px;
}

.domain-status-alert {
    /*border: 1px solid #999999;*/
    width: 50%;
    height: 50px;
    margin: 0 auto;
    margin-top: 30px;
}

.domain-status-box-header {
    font-weight: 600;
    border-bottom: 1px solid #EBEDEE;
    margin-bottom: 20px;
    padding: 10px;
}

.main-box {
    width: 70%;
    margin: 0 auto;
}

.padded {
    padding-top: 30px;
}

.domain-status-box-registrar {
    font-size: 16px;
    margin-bottom: 20px;
}

.domain-status-box-date {
    /*margin-bottom: 20px;*/
}

.approved {
    color: #56C22D;
}

.rejected {
    color: red;
}

.status-arrow {
    font-size: 25px;
    font-weight: 600;
}

.default {
    color: #0e77ca;
}

.ant-btn-accept:hover, .ant-btn-accept:focus {
    color: #fff;
    background-color: #56C22D;
    border-color: #56C22D;
}

.ant-btn-accept {
    color: #56C22D;
    background-color: #f5f5f5;
    border-color: #d9d9d9;
}

.domain-status-box-title {
    margin-top: 22px;
    font-size: 16px;
    font-weight: 600;
}

.buttons {
    margin-top: 90px;
    border-top: 1px solid #e9e9e9;
    padding-top: 20px;
    text-align: center;
}

.cancel-button {
    margin-right: 25px;
}

.accept-button {
    margin-right: 10px;
}
.contact-list {
    padding: 10px;
}
.domains-list {
    padding: 10px;
}
.filter-div {
    padding-top: 15px;
    padding-bottom: 10px;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    z-index: 2;
    background-color: white;
    align-items: center;
}

.filter-div-options {
    padding-top: 0px !important;
}

.filter-menu {
    /*border-bottom: 1px solid #e5e5e5;*/
}

.extra {
    width: 250px;
}

.filter-menu-input-search {
    padding: 10px;
}

.filter-menu-item {
    height: 30px !important;
    padding-left: 10px !important;
}

.filter-menu-row {
}

.filter-menu-icon {
    color: black;
    vertical-align: 0 !important;
    /*margin-right: 5px;*/
    width: 25px !important;
}

.filter-menu-wrapped {
    margin-left: 20px;
}

.filter-menu-text {
    vertical-align: 1px !important;
}

.operation {
    display: block;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
}

/* mouse over link */
.operation:hover {
    color: #1890ff;
    cursor: pointer;
}

.filter-bar-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #e5e5e5;
    padding: 5px;
    border-radius: 5px;
}

.filter-bar-left {
    display: flex;
    align-items: center;
    width: 100%;
    /*height: auto;*/
    /*border: 1px solid;*/
}

.filter-bar-right {
    display: flex;
    align-items: center;
    width: 20px;
}

/* mouse over link */
.filter-bar-right:hover {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #1890ff;
    font-weight: bold;
}

.filter-options-exit {
    padding: 1px;
    margin: 2px;
    border-radius: 3px;
    border: 1px solid #e5e5e5;
}

.filter-options-exit:hover {
    cursor: pointer;
    color: #1890ff;
}

.div-exit {
    /*border-bottom: 1px solid #e5e5e5;*/
    display: flex;
    justify-content: flex-end;
}
.main {
    background-color: white;
    margin: 10px auto;
    width: 90%;
    padding: 20px;
    text-align: center;
}

.welcome {
    display: block;
    font-size: 14px;
    margin-top: 20px;
}

.card-icon {
    font-size: 40px;
}

.home-row {
    padding-top: 10px;
}
.contact-status-bar {
    font-weight: 600;
}

.contact-status-bar .ant-tag {
    line-height: 30px;
    height: 30px;
    font-size: 9pt;
    cursor: default;
    background-color: transparent;
}

.contact-status-bar-icon {
    font-size: 12pt;
    font-weight: 100;
    color: #b3b3b3;
    margin-right: 5px;
}

.break-line {
    border-left: 1px solid #b3b3b3;
    margin-right: 15px;
    margin-left: 15px;
}

.dynamic-delete-button {
    cursor: pointer;
    position: relative;
    top: 4px;
    font-size: 24px;
    color: #999;
    transition: all .3s;
}
.dynamic-delete-button:hover {
    color: #777;
}
.dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}
.itlogo-footer {
    height: 20px;
    width: auto;
    position: relative;
    top: 7px;
    left: -5px;
}

a.footer-link {
    color: white;
    font-weight: 700;
    font-size: 11pt;
}

.epic-footer {
    text-align: center;
    padding-top: 5px;
    padding-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.65) !important;
    color: white;
}

.eppChecked-icon {
    margin-left: 15px;
    font-size: 40pt;
    font-weight: 700;
    line-height: 1.5;
}

.eppChecked-icon-success {
    color: #18A658
}

.eppChecked-icon-fail {
    color: #ED433C
}

.eppChecked-box-message-success {
    background-color: #EBF8F2;
}

.eppChecked-box-message-fail {
    background-color: #fff0ef;
}

.eppChecked-box-success {
    border: 1px solid #D0EFDF;
}

.eppChecked-box-fail {
    border: 1px solid #FCDBD9;
}

.eppChecked-title {
    margin-left: 10px;
    /*font-size: 20px;*/
    padding-bottom: 20px;
}

.eppChecked-box {
    border-radius: 5px;
    padding-bottom: 20px;
}

.eppChecked-box-message {
    padding: 10px;
    margin-bottom: 30px;
    color: black;
}

.eppChecked-box-extra {
    margin: 30px 80px;
    padding: 20px;
    background-color: #FAFAFA;
    font-size: 10pt;
}

.eppChecked-box-body {
    margin-bottom: 20px;
    font-size: 14pt;
    font-weight: 700;
}

/*.eppChecked-card {*/
    /*padding-left: 35%;*/
    /*padding-right: 35%;*/
    /*font-size: 12pt;*/
/*}*/
.epp-messages-list {
    padding: 10px;
}

.ant-table-placeholder {
    z-index: 0;
}
.message-read {
    font-weight: normal;
}

.message-unread {
    font-weight: bold;
}

.message-detail {
    /*padding: 10px;*/
    /*border: 1px solid #E8E8E8;*/
}

.message-detail .unread {
    background-color: grey;
}

.selected {
    background-color: red;
}

.current {
    background-color: #E7F7FF;
}

.table-alert {
    padding-bottom: 10px;
    width: auto;
}

.table-hint {
    /*float: left;*/
    /*margin: 0 auto*/
    font-size: 12px;
}


.vertical-center-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.vertical-center-modal .ant-modal {
    top: 0;

}
.header-left {
    float: left;
    padding-left: 20px;
    text-align: left;
}

.header-right {
    float: right;
    padding-right: 20px;
    text-align: right;
}

.domain {
    font-size: 19px;
    font-weight: bold;
    display: block;
}

.type {
    font-size: 16px;
    /*font-weight: bold;*/
    display: block;
}

.date {
    color: #BFBFC3;
    font-size: 12px;
    /*font-weight: bold;*/
    display: block;
}

.status {
    margin-top: 5px;
    color: #BFBFC3;
    font-size: 11px;
    display: block;

}

.eppMessage-detail-header {
    padding: 20px;
    background-color: #FAFAFA;
    border-top: 1px solid #E8E8E8;
}

.eppMessage-detail-body {
    /*background-color: red;*/
    padding: 30px;
    /*display: flex;*/
    /*align-content: flex-start;*/
    /*justify-content: flex-start;*/
}

.message-detail-icon {
    padding-top: 2px;
    padding-bottom: 3px;
    /*margin-left: 10px;*/
    background-color: #CCCCCC;
    /*border: 2px solid ;*/
    border-radius: 50px 20px;
    font-weight: bold;
    color: white;
}

.eppMessage-detail-body {
    /*background-color: magenta;*/
}

.body-container {
    /*background-color: green;*/
    /*border: 1px solid #e5e5e5;*/
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap
}

.base-message {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: left;
    /*background-color: blue;*/
    width: 100%;
}

.typedLayout-container {
    /*background-color: yellow;*/
    /*text-align: left;*/
    /*display: flex;*/
}

.typedLayout-data {
    border: 1px solid #e5e5e5;
    padding: 20px 100px 20px 0px;
}

.typedLayout-header {
    font-weight: bold;
    text-align: left;
    padding-bottom: 5px;
    width: 100%;
}

.typedLayout-row {
    text-align: left;
    width: 100%;
    padding-top: 10px;
    padding-left: 10px;
}

.label {
    display: inline-block;
    width: 150px;
    text-align: right;
    font-weight: bold;
    font-size: 10pt;
    line-height: 2em;
    vertical-align: top;
}

.label-mini {
    width: 100px;
}

.content {
    position: relative;
    display: inline-block;
    text-align: left;
    line-height: 2em;
    font-size: 10pt;
    margin-left: 5px;
    /*margin-right: 180px;*/
    max-width: 800px;

}

.unstyled-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

pre.body {
    width: 100%;
    display: block;
    max-height: 500px;
    overflow: auto;
    margin: 0;
    padding: 0 5px;
    background: #F3F2F2;
    background-image: linear-gradient(#F3F2F2 50%, #EBEAEA 50%);
    background-position: 0 0;
    background-repeat: repeat;
    background-attachment: local;
    background-size: 4em 4em;
    line-height: 2em;
    display: none;
}

.dns-error-test {
    border: 1px solid #e5e5e5;
    padding: 10px;
    margin-top: 5px;
}

.dns-error-row {
    text-align: left;
    width: 100%;
    padding-top: 3px;
    /*padding-left: 10px ;*/
}

.dns-error-header {
    padding-top: 20px;
    font-weight: bold;
    font-style: oblique;
}

.dns-error-label {
    font-weight: bold;
    text-align: left;
}

.dns-error-nameserver {
    padding: 10px;
    border: 1px solid #e5e5e5;
}

.pop-over {
    max-width: 800px;
}
body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

