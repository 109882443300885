div.ant-col-12 .ant-table-wrapper {
    width: 100% !important;
}

.hosts-table {
    margin-bottom: 20px;
}

/*tr:nth-child(even) {*/
/*background: #fcfcfc*/
/*}*/

/*tr:nth-child(odd) {*/
/*background: #FfFF*/
/*}*/

