.domain-status-box {
    /*border: 1px solid #999999;*/
    width: 90%;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 60px;
}

.domain-status-alert {
    /*border: 1px solid #999999;*/
    width: 50%;
    height: 50px;
    margin: 0 auto;
    margin-top: 30px;
}

.domain-status-box-header {
    font-weight: 600;
    border-bottom: 1px solid #EBEDEE;
    margin-bottom: 20px;
    padding: 10px;
}

.main-box {
    width: 70%;
    margin: 0 auto;
}

.padded {
    padding-top: 30px;
}

.domain-status-box-registrar {
    font-size: 16px;
    margin-bottom: 20px;
}

.domain-status-box-date {
    /*margin-bottom: 20px;*/
}

.approved {
    color: #56C22D;
}

.rejected {
    color: red;
}

.status-arrow {
    font-size: 25px;
    font-weight: 600;
}

.default {
    color: #0e77ca;
}

.ant-btn-accept:hover, .ant-btn-accept:focus {
    color: #fff;
    background-color: #56C22D;
    border-color: #56C22D;
}

.ant-btn-accept {
    color: #56C22D;
    background-color: #f5f5f5;
    border-color: #d9d9d9;
}

.domain-status-box-title {
    margin-top: 22px;
    font-size: 16px;
    font-weight: 600;
}

.buttons {
    margin-top: 90px;
    border-top: 1px solid #e9e9e9;
    padding-top: 20px;
    text-align: center;
}

.cancel-button {
    margin-right: 25px;
}

.accept-button {
    margin-right: 10px;
}