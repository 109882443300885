.ant-col-sm-tech {
  width: 63.5%;
}

.ant-col-sm-contact {
  width: 50%;
}



.contact-info-alignment {
  float: left;
  margin-top: 5px;
  margin-bottom: 10px;
}

.contact-info-bar {
  color: #b3b3b3;
  margin-right: 5px;
}

.contactPopover {
  width:30%;
}

div.ant-col-sm-tech div.ant-form-item-control.has-feedback.has-success:after,
div.ant-col-sm-tech div.ant-form-item-control.has-feedback.has-warning.has-feedback:after,
div.ant-col-sm-tech div.ant-form-item-control.has-feedback.has-error.has-feedback:after,
div.ant-col-sm-tech div.ant-form-item-control.has-feedback.is-validating.has-feedback:after {
  position: absolute;
  padding-right: 57px;
  padding-top: 2px;
  top: 0;
  right: 0;
  visibility: visible;
  pointer-events: none;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  font-family: 'anticon';
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  z-index: 1;
}

div.ant-col-sm-tech div.ant-form-item-control.has-success.has-feedback :after,
div.ant-col-sm-tech div.ant-form-item-control.has-warning.has-feedback:after,
div.ant-col-sm-tech div.ant-form-item-control.has-error.has-feedback:after,
div.ant-col-sm-tech div.ant-form-item-control.is-validating.has-feedback:after {
  position: absolute;
  padding-right: 57px;
  padding-top: 2px;
  top: 0;
  right: 0;
  visibility: visible;
  pointer-events: none;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  font-family: 'anticon';
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  z-index: 1;
}


div.ant-col-sm-contact div.ant-form-item-control.has-feedback.has-success:after,
div.ant-col-sm-contact div.ant-form-item-control.has-feedback.has-warning.has-feedback:after,
div.ant-col-sm-contact div.ant-form-item-control.has-feedback.has-error.has-feedback:after,
div.ant-col-sm-contact div.ant-form-item-control.has-feedback.is-validating.has-feedback:after {
  position: absolute;
  padding-right: 57px;
  padding-top: 2px;
  top: 0;
  right: 0;
  visibility: visible;
  pointer-events: none;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  font-family: 'anticon';
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  z-index: 1;
}


div.ant-col-sm-contact div.ant-form-item-control.has-success.has-feedback :after,
div.ant-col-sm-contact div.ant-form-item-control.has-warning.has-feedback:after,
div.ant-col-sm-contact div.ant-form-item-control.has-error.has-feedback :after,
div.ant-col-sm-contact div.ant-form-item-control.is-validating.has-feedback:after {
  position: absolute;
  padding-right: 57px;
  padding-top: 2px;
  top: 0;
  right: 0;
  visibility: visible;
  pointer-events: none;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  font-family: 'anticon';
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  z-index: 1;
}
