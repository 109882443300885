.epp-messages-list {
    padding: 10px;
}

.ant-table-placeholder {
    z-index: 0;
}
.message-read {
    font-weight: normal;
}

.message-unread {
    font-weight: bold;
}

.message-detail {
    /*padding: 10px;*/
    /*border: 1px solid #E8E8E8;*/
}

.message-detail .unread {
    background-color: grey;
}

.selected {
    background-color: red;
}

.current {
    background-color: #E7F7FF;
}

.table-alert {
    padding-bottom: 10px;
    width: auto;
}

.table-hint {
    /*float: left;*/
    /*margin: 0 auto*/
    font-size: 12px;
}


.vertical-center-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.vertical-center-modal .ant-modal {
    top: 0;

}